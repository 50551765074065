const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
  },

  navbarRow: {
    height: "72px",
    borderBottom: "1px solid #E3E6EA",
  },

  contentRow: {
    display: "flex",
  },
  sideBarContainer: {
    width: "80px",
    height: "calc(100vh - 72px)",
    borderRight: "1px solid #E3E6EA",
  },

  drawer: {
    width: "300px",
    height: "calc(100vh - 72px)",
    borderRight: "1px solid #E3E6EA",
  },

  children: {
    width: "100%",
    borderRight: "1px solid #E3E6EA",
  },
};

export default styles;
