import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { LoadingButton } from "@mui/lab";
import { Box, styled, Button, IconButton, CircularProgress } from "@mui/material";
import { useState } from "react";
import { IoArrowForward } from "react-icons/io5";
import { PiMicrophoneBold } from "react-icons/pi";
import styles from "./styles";

const TextareaAutosize = styled(BaseTextareaAutosize)({
  borderRadius: "24px",
  minHeight: "10px",
  border: "1px solid #D0D5DD",
  resize: "none",
  fontFamily: "Satoshi, sans-serif",
  fontSize: "20px",
  padding: "10px 14px",
  paddingBottom: "10px",
  fontWeight: 500,
  outline: "none",
  width: "100%",

  "&::placeholder": {
    color: "#D0D5DD",
  },
});

export default function QuestionInput({
  askManualQuestion,
  state,
  startRecording,
  stopRecording,
  hasChats
}) {

  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = question.trim();
    if (!message) return;

    setLoading(true);
    askManualQuestion(message);
    setLoading(false);
    setQuestion("");
  };

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };

  // In the text area,
  // pressing enter asks the question.
  // Pressing shift+enter adds a new line.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        event.preventDefault();
        setQuestion(q => q + "\n");
      } else {
        handleSubmit(event);
      }
    }
  };

  return (
    <Box
      component="form"
      sx={styles.root}
      onSubmit={handleSubmit}
    >
      <Box sx={{ mr: "10px" }}>
        {state === "recording" ? (
          <Button
            variant="contained"
            color="error"
            onClick={stopRecording}
            startIcon={<Box sx={styles.stopIcon} />}
            sx={styles.stopButton}
          >
            Stop
          </Button>
        ) : hasChats && (
          <IconButton
            onClick={startRecording}
            sx={styles.recordButton}
            disabled={state === "halting" || state === "stopping"}
          >
            {state === "halting" || state === "stopping" ? (
              <CircularProgress size={24} />
            ) : (
              <PiMicrophoneBold color="#000" />
            )}
          </IconButton>
        )}
      </Box>

      <TextareaAutosize
        placeholder="Ask questions in this field"
        value={question}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />

      <LoadingButton
        variant="contained"
        type="submit"
        loading={loading}
        sx={styles.submitButton}
      >
        <IoArrowForward size={15} />
      </LoadingButton>
    </Box>
  );
}
