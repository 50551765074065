import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { IoPersonCircleSharp } from "react-icons/io5";
import UserInfo from "containers/UserInfo";
import { ReactComponent as UndoIcon } from "assets/icons/undo.svg";
import TranscriptIcon from "assets/icons/transcript";
import LightBulbIcon from "assets/icons/bulb";
import LiveTranscription from "components/LiveTranscriptionV2";
import styles from "./styles";

export default function Drawer({
  open,
  customer,
  setCustomer,
  state,
  transcript,
  setTranscript,
  boxRef,
  allBrains,
  onSelectedBrainChange,
  selectedBrain,
  }) {

  const [history, setHistory] = useState(["search"]);
  const [selectedTab, setSelectedTab] = useState("transcript");

  return (
    <Box sx={{ ...styles.root, transform: open ? "translateX(0)" : "translateX(100%)" }}>
      <Box sx={styles.topRow}>
        <Box
          sx={{
            ...(selectedTab === "knowledge-base" ? styles.selectedIconButton : {}),
            height: "100%"
          }}
        >
          <IconButton
            sx={styles.iconButton}
            onClick={() => setSelectedTab("knowledge-base")}
          >
            <LightBulbIcon
              color={ selectedTab === "knowledge-base" ? "#6552F3" : "#98A2B3"}
            />
          </IconButton>
        </Box>

        <Box
          sx={{
            ...(selectedTab === "customer" ? styles.selectedIconButton : {}),
            height: "100%"
          }}
        >
          <IconButton
            sx={styles.iconButton}
            onClick={() => setSelectedTab("customer")}
          >
            <IoPersonCircleSharp
              color={ selectedTab === "customer" ? "#6552F3" : "#98A2B3"}
            />
          </IconButton>
        </Box>

        <Box
          sx={{
            ...(selectedTab === "transcript" ? styles.selectedIconButton : {}),
            height: "100%"
          }}
        >
          <IconButton
            sx={styles.iconButton}
            onClick={() => setSelectedTab("transcript")}
          >
            <TranscriptIcon
              color={selectedTab === "transcript" ? "#6552F3" : "#98A2B3"}
            />
          </IconButton>
        </Box>
      </Box>
      <Box sx={styles.contentArea}>
        {selectedTab === "knowledge-base" && (
          <Box sx={{ width: "100%" }}>
            <Typography sx={{ ...styles.title, mb: "30px" }}>
              Knowledge Base
            </Typography>

            <FormControl fullWidth>
              <Select
                value={selectedBrain}
                onChange={onSelectedBrainChange}
                sx={styles.selectBrainDropdown}
              >
                {allBrains.map((brain) => (
                  <MenuItem value={brain.id}>{brain.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {selectedTab === "customer" && (
          <Box>
            <Typography sx={styles.title}>
              Customer Details
            </Typography>

            {history.length > 1 && (
              <IconButton
                sx={{ fontSize: "20px", color: "gray.700" }}
                onClick={() => {
                  setHistory(history.slice(0, history.length - 1));
                  setCustomer(null);
                }}
              >
                <UndoIcon />
              </IconButton>
            )}

            <UserInfo
              history={history}
              setHistory={setHistory}
              user={customer}
              setUser={setCustomer}
            />
          </Box>
        )}

        {selectedTab === "transcript" && (
          <Box>
              <LiveTranscription
                state={state}
                transcript={transcript}
                setTranscript={setTranscript}
                boxRef={boxRef}
              />
          </Box>
        )}
      </Box>
    </Box>
  );
}
