import { Box, Typography, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { PiMicrophoneBold } from "react-icons/pi";
import Response from "components/ResponseV2";
import QuestionInput from "components/QuestionInputV2";
import styles from "./styles";

export default function ChatBox({
  chatHistory,
  setChatHistory,
  processModel,
  threadIdRef,
  setIsAnswering,
  customer,
  state,
  startRecording,
  stopRecording,
  handleAskManualQuestion,
}) {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.chatBox}>
        {chatHistory.length === 0 ? (
          <Box sx={styles.emptyStateBox}>
            <Typography variant="h7" color="gray.300" fontWeight={700}>
              {state === "recording" ? "Listening..." : "Click Below To Speak"}
            </Typography>

            {state !== "recording" && (
              <LoadingButton
                variant="contained"
                sx={styles.recordButton}
                loading={state === "halting" || state === "stopping"}
                onClick={startRecording}
                startIcon={state !== "halting" && <PiMicrophoneBold />}
              >
                Start
              </LoadingButton>
            )}
          </Box>
        ) : (
          chatHistory.map((c, index) => (
            <Response
              key={index}
              index={index}
              setChatHistory={setChatHistory}
              processModel={processModel}
              isLast={index === chatHistory.length - 1}
              threadIdRef={threadIdRef}
              setIsAnswering={setIsAnswering}
              customer={customer}
              {...c}
            />
          ))
        )}
      </Box>
      <Box sx={styles.questionInputContainer}>
        <QuestionInput
          askManualQuestion={handleAskManualQuestion}
          state={state}
          startRecording={startRecording}
          stopRecording={stopRecording}
          hasChats={chatHistory.length > 0}
        />
      </Box>
    </Box>
  );
}
