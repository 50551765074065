const styles = {
  stopButton: {
    fontSize: "18px",
    mx: "auto",
    minWidth: "120px",
  },
  stopIcon: {
    height: "18px",
    width: "18px",
    borderRadius: "4px",
    bgcolor: "#fff",
  },

  recordButton: {
    border: "1px solid #E3E6EA",
    p: "10px",
  },

  root: {
    display: "flex",
    justifyContent: "center",
    position: "relative"
  },

  submitButton: {
    minWidth: 0,
    padding: "14px",
    position: "absolute",
    bottom: "3px",
    right: "3px",
  },
};

export default styles;
