import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function EmptyState({ text }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h7" color="gray.300" fontWeight={700}>
        {text}
      </Typography>
    </Box>
  );
}
