const styles = {
  root: {
    position: "absolute",
    width: "500px",
    bgcolor: "#F9FAFB",
    borderLeft: "1px solid #DCE0E5",
    height: "calc(100% - 72px)",
    bottom: 0,
    right: 0,
    transition: "transform 0.3s ease-in-out",
    overflowY: "auto",
  },

  topRow: {
    height: "64px",
    borderBottom: "1px solid #E3E6EA",
    display: "flex",
    alignItems: "center",
  },

  iconButton: {
    mx: "15px",
    mt: "10px",
  },

  selectedIconButton: {
    borderBottom: "2px solid #6552F3",
  },

  title: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#1B2730",
  },

  contentArea: {
    padding: "32px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "16px",
  },

  selectBrainDropdown: {
  },
};

export default styles;
