const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    flex: "1 1 45%",
  },

  transcriptBox: {
    padding: "36px",
    borderRadius: "24px",
    border: "1px solid",
    borderColor: "gray.300",
    height: "400px",
    minWidth: "400px",
    width: "100%",
    overflowY: "auto",
    flexShrink: 0,
    background: "#FFF",
  },

  title: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#1B2730",
  },

  transcriptText: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#475467",
    whiteSpace: "pre-wrap",
  },
};

export default styles;
