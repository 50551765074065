import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { ReactComponent as RenewIcon } from "assets/icons/renew.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as StarIconOutline } from "assets/icons/star-outline-gray-thick.svg";
import { ReactComponent as StarIconSolid } from "assets/icons/star-solid-gray.svg";
import Markdown from "react-markdown";
import { AppContext } from "App";
import { askBrain, askLLM, rateAnswer } from "services/api";
import CustomSelect from "components/CustomSelect";
import RateAnswer from "components/RateAnswer";
import styles from "./styles";

const sizes = [
  { id: "small", name: "Short" },
  { id: "medium", name: "Medium" },
  { id: "large", name: "Long" },
];

function Response({
  index,
  assistant,
  setChatHistory,
  threadIdRef,
  isLast,
  processModel,
  question,
  answer = "",
  setIsAnswering,
  customer,
}) {
  const [curAnswer, setCurAnswer] = useState(answer);
  const [isGenerating, setIsGenerating] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rateAnswerAnchorEl, setRateAnswerAnchorEl] = useState(null);
  const [answerRating, setAnswerRating] = useState(null);
  const { notifyError } = useContext(AppContext);
  const mounted = useRef(false);
  const responseRef = useRef(null);

  const readResponse = async (res) => {
    if (!res.ok) {
      notifyError("Error reading from stream", 4000);
    }

    threadIdRef.current = res.headers.get("thread_id");
    const reader = res.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let message = "";

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value, { stream: true });
      message += chunk;
      setCurAnswer(message);

      if (isLast) responseRef.current.scrollIntoView({ behavior: "auto" });
    }

    setChatHistory((prev) =>
      prev.map((q, i) => (i === index ? { ...q, answer: message } : q))
    );
    setIsAnswering(false);
  };

  const askQuestion = async (size) => {
    setCurAnswer("");
    setIsGenerating(true);

    if (assistant === "default") {
      const res = await askLLM(
        question,
        processModel,
        threadIdRef.current,
        size
      ).catch((e) => {
          notifyError(e.message, 4000);
          setIsAnswering(false);
      });

      await readResponse(res);
    } else {
      const customerId = customer ? customer.user_id : null;
      const res = await askBrain(
        question,
        assistant,
        threadIdRef.current,
        size,
        customerId
      ).catch((e) => {
          notifyError(e.message, 4000);
          setIsAnswering(false);
      });

      await readResponse(res);
    }

    setIsGenerating(false);
  };

  useEffect(() => {
    if (!mounted.current && !curAnswer) {
      if (isLast) responseRef.current.scrollIntoView({ behavior: "auto" });
      askQuestion("small");
      mounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copy = () => {
    if (!copySuccess) {
      navigator.clipboard.writeText(curAnswer).then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      });
    }
  };

  const handleRateIconClick = (e) => {
    setRateAnswerAnchorEl(e.currentTarget);
  };

  const handleRatePopoverClose = () => {
    setRateAnswerAnchorEl(null);
  };

  const handleSubmitRating = (newRating, feedback) => {
    setAnswerRating(newRating);
    rateAnswer(threadIdRef.current, curAnswer, newRating + 1, feedback);
  };

  return (
    <div ref={responseRef}>
      <Box sx={styles.questionBox}>
        <Typography sx={styles.questionText}>
          {question}
        </Typography>
      </Box>

      <Box sx={styles.responseBox}>
        <Typography
          component="div"
          fontStyle={!curAnswer ? "italic" : "normal"}
          sx={styles.responseText}
        >
          <Markdown>{answer || curAnswer || "Generating..."}</Markdown>
        </Typography>

        {!isGenerating && (
          <Box sx={styles.actions}>
            <Tooltip
              arrow
              title={copySuccess ? "Copied" : "Copy"}
              slotProps={styles.tooltip}
            >
              <IconButton
                onClick={copy}
                sx={{
                  ...styles.iconButton,
                  color: copySuccess ? "success.main" : "inherit",
                }}
              >
                <Box sx={styles.iconBox}>
                  {copySuccess ? <FaCheck size="18px" /> : <CopyIcon />}
                </Box>
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Regenerate" slotProps={styles.tooltip}>
              <IconButton
                sx={styles.iconButton}
                onClick={(e) => setAnchorEl(e.target)}
              >
                <RenewIcon />
              </IconButton>
            </Tooltip>
            <CustomSelect
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              options={sizes}
              onSelect={(opt) => askQuestion(opt.id)}
              onClose={() => setAnchorEl(null)}
            />

            <IconButton
              sx={{ ...styles.iconButton, mr: "5px" }}
              onClick={handleRateIconClick}
            >
              {answerRating !== null ? <StarIconSolid /> : <StarIconOutline />}
            </IconButton>
            <RateAnswer
              popoverId="rate-answer"
              open={Boolean(rateAnswerAnchorEl)}
              anchorEl={rateAnswerAnchorEl}
              onClose={handleRatePopoverClose}
              rating={answerRating}
              submitRating={handleSubmitRating}
            />
          </Box>
        )}
      </Box>
    </div>
  );
}

export default Response;
