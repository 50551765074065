const styles = {
  topRow: {
    height: "64px",
    borderBottom: "1px solid #E3E6EA",
    display: "flex",
    alignItems: "center",
  },

  nameLabel: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "23px",
    mx: "20px",
    color: "#1B2730",
  },

  timer: {
    background: "#F6F7F9",
    display: "flex",
    alignItems: "center",
    ml: "10px",
    borderRadius: "10px",
    padding: "8px",
  },
  dot: {
    height: "8px",
    width: "8px",
    backgroundColor: "#2EC209",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "8px",
  },
  timerText: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "23px",
    color: "#667085",
  },

  toggleButton: {
    ml: "auto",
    mr: "20px",
    border: "1px solid #E3E6EA",
  },
};

export default styles;
